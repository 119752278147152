@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply flex h-full grow flex-col bg-[#1c2748] text-white;
}

body {
  @apply m-0;
}

*,
::before,
::after {
  @apply box-border border-0 border-solid border-current;
}

a {
  color: #517bca;
  text-decoration: underline;
}

span {
  color: #6a7690;
  width: 200px;
  display: inline-block;
  font-weight: 600;
}

h2 {
  margin: 30px 0 30px;
}

p {
  margin: 5px 0;
}
